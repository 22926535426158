export default {
  breakpoints: [
    '40em', '48em', '64em',
  ],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  sizes: ['100%', '70%'],
  fonts: {
    body: 'Manrope, "Helvetica Neue", system-ui, sans-serif',
    title: 'Manrope,"Helvetica Neue", system-ui, sans-serif',
    heading: 'Manrope,"Helvetica Neue", system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  fontWeights: {
    xlight: 200,
    light: 300,
    body: 400,
    medium: 500,
    heading: 700,
    bold: 700,
    xbold: 800
  },
  lineHeights: {
    body: 1.618,
    list: 1.25,
    heading: 1.125
  },
  colors: {
    text: '#000',
    background: '#fff',
    gray: '#666',
    lightgray: '#999',
    primary: '#ff7300',
    primaryTransparent: '#ff730040',
    primaryHalf: '#f7b87e',
    secondary: '#f00',
    muted: '#f6f6f6'
  },
  shadows: {
    project: {
      shadow: '1px 1px 2px rgba(0,0,0,0.75)',
      aboutCircles: '0px 5px 5px 3px rgba(0,0,0,0.25)'
    },
  },
  borders: {
    project: {
      underline: 'solid primary 5px',
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'light',
      a: {
        textDecoration: 'none',
        color: 'primary',
      },
      strong:{
        fontWeight: 'medium',
        backgroundColor: 'primaryHalf',
        px: 1,
      },
      '*': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      },
      '.projectMDX hr' : {
        gridColumnStart: 'span 5',
      },
      '.projectMDX div' : {
        gridColumnStart: 'span 5',
      },
      '.projectMDX img' : {
        gridColumnStart: 'span 5',
      },
      '.projectMDX p' : {
        gridColumnStart: '2',
        gridColumnEnd: '5',
        fontSize: [1,2],
        letterSpacing: ['0px','1px'],
      },
      '.projectMDX ul, ol, li, blockquote' : {
        gridColumnStart: '2',
        gridColumnEnd: '4',
        fontSize: [1,2],
        letterSpacing: ['0px','1px'],
      },
      '.projectMDX h2, .projectMDX h3' : {
        color: 'gray',
        gridColumnStart: '1',
        textAlign: ['left','right'],
        fontWeight: 'light',
      },
      '.projectMDX h4, .projectMDX h5' : {
        color: 'gray',
        gridColumnStart: '1',
        textAlign: 'right',
        fontWeight: 'light',
      },
      '.projectMDX ul, ol' : {
        mb: 3,
      },
      '.projectMDX li' : {
        borderBottom: '1px dashed',
        borderBottomColor: 'primary',
      },
      '.projectMDX table' : {
        gridColumnStart: 'span 5',
      },
      '.projectMDX blockquote' : {
        marginInlineStart: '0',
        marginInlineEnd: '0',
      },
      '#behanceIcon': {
        color: 'gray',
        ':hover':{
          color: '#053eff'
        }
      },
      '#linkedinIcon': {
        color: 'gray',
        ':hover':{
          color: '#0072b1'
        }
      },
      '.circles': {
        fill: 'lightgray',
        cursor: 'help',
        strokeWidth:'3',
        stroke:'background',
        transition: 'fill .25s ease-in, stroke-width .25s ease-in!important',
        ':hover':{
          fill: 'primaryHalf',
        },
      },
      '.circlesText': {
        fill: 'background',
        fontWeight: 'bold',
        fontSize: '3',
      },
      '.circles.active':{
        fill: 'primary',
        strokeWidth: '5',
      },

      //   '#projectBox::after': {
      //     backgroundColor: '#000',
      //     bottom: 0,
      //     content: "''",
      //     left: 0,
      //     opacity: .1,
      //     position: 'absolute',
      //     right: 0,
      //     top: 0,
      // },

      'h2:empty': {
        display: 'none'
      },
      '#projectBox img': {
        transition: 'transform .25s ease-in!important',
      },
      '#projectBox:hover img': {
        transform: 'scale(1.15)',
      },
      '.Typewriter__cursor':
      {
        fontSize: [4, 5, 6],
        fontFamily: 'heading',
        fontWeight: 'light',
        color: 'primary',
        lineHeight: '0px',
        verticalAlign: ['initial', 'baseline', 'sub'],
      },
      '.typed': {
        display: ['block','initial']
      },
      // 'a::after': {    
      //   background: 'none repeat scroll 0 0 transparent',
      //   bottom: 0,
      //   content: '""',
      //   display: 'block',
      //   height: '2px',
      //   left: '50%',
      //   position: 'absolute',
      //   backgroundColor: 'primary',
      //   transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      //   width: '0',
      // },
      // 'a:hover::after': { 
      //   width: '100%',
      //   left: 0,
      // },
      '.active': {
        borderBottom: '2px solid',
        ':hover': {
          borderBottom: '2px solid',
        },
      },
      hr:{
        border: 'none',
        borderTop: '2px dashed',
        color: 'primary',
        overflow: 'visible',
        textAlign: 'center',
        height: '5px',
      },
      h1: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: ['3','6'],
        letterSpacing: '-1px',
        mb: 3
      },
      h2: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'body',
        fontSize: ['1','3'],
      },
      h3: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'body',
        fontSize: ['2','3'],
        mt: 1,
        mb: 1,
      },
      h4: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'body',
        fontSize: ['0','2'],
      },
      h5: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'body',
        fontSize: 1
      },
      h6: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 0
      },
      p: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'light',
        lineHeight: 'body',
        fontSize: 3,
      },
      li: {
        mb: '2'
      },
      ul: {
        color: 'text',
        fontFamily: 'body',
        lineHeight: 'body',
        listStyleType: 'none',
        paddingInlineStart: '0',
        fontSize: 3,
      },
      ol: {
        color: 'text',
        fontFamily: 'body',
        lineHeight: 'body',
        listStyleType: 'none',
        paddingInlineStart: '0',
        fontSize: 3,
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit'
        }
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit'
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      img: {
        maxWidth: '100%'
      },
    }
  }
}